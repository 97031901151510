.container {
    composes: container from '../../theme.module.css';
    background-color: #1E3B47;
}

.mainContent {
    composes: mainContent from '../../theme.module.css';
    box-sizing: border-box;
    flex-direction: column;
}

.featureContainer {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.feature {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 30%;
    min-width: 300px;
    height: 365px;
    margin-bottom: 32px;
}

.feature>img {
    width: 100%;
    margin-bottom: 16px;
    border-radius: 5px;
}

.featureHeader {
    composes: body-1 from '../../theme.module.css';
    font-weight: 700;
    color: #FFFFFF;
    margin-bottom: 16px;
}

.description {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: clamp(1rem, 0.9656rem + 0.1328vw, 1.125rem);
    line-height: 150%;
    color: rgba(255, 255, 255, 0.85);
}

.buttonContainer {
    display: flex;
    flex-wrap: wrap;
    margin-top: 48px;
}

.buttonContainer>button:first-child {
    margin-right: 24px;
}

.primaryButton {
    composes: button-2 from "../../theme.module.css";
    composes: primaryButtonDark from "../../theme.module.css";
    width: 170px;
}

.secondaryButtonDark {
    composes: button-2 from "../../theme.module.css";
    composes: secondaryButtonDark from "../../theme.module.css";
    width: 200px;
}

@media (max-width: 959.95px) {
    .container {
        padding: 24px;
    }

    .feature {
        width: 100%;
        height: 300px;
        min-width: 100% !important;
        margin-bottom: 48px;
    }

    .feature>img {
        height: 60%;
        object-fit: cover;
    }

    .buttonContainer {
        margin-top: 32px;
        width: 100%;
    }

    .buttonContainer>button:first-child {
        margin-bottom: 24px;
    }
}

@media (min-width: 320px) and (max-width: 768px) {

    .primaryButton,
    .secondaryButtonDark {
        align-self: center;
        width: 100%;
    }

    .primaryButton {
        margin-right: 0 !important;
    }
}