/* CONTAINER STYLES */
.container {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 200px 120px;
    background-color: #FFFFFF;
}

.container>.mainContent {
    width: 90%;
    display: flex;
    max-width: 1440px;
}

@media (min-width: 391px) and (max-width: 768px) {
    .container {
        padding: 72px 24px !important;
    }
}

@media (min-width: 320px) and (max-width: 390px) {
    .container {
        padding: 72px 0 !important;
    }
}

/* TEXT STYLES */
.heading {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    margin: 0;
    color: #000000;
}

.heading-1 {
    composes: heading;
    font-size: clamp(2.125rem, 1.3003rem + 3.1873vw, 5.125rem);
}

.heading-2 {
    composes: heading;
    font-size: clamp(1.875rem, 1.2908rem + 2.2576vw, 4rem);
}

.heading-3 {
    composes: heading;
    font-size: clamp(1.625rem, 1.2814rem + 1.328vw, 2.875rem);
}

.heading-4 {
    composes: heading;
    font-size: clamp(1.375rem, 1.2719rem + 0.3984vw, 1.75rem);
}

.heading-5 {
    composes: heading;
    font-size: clamp(1.25rem, 1.2156rem + 0.1328vw, 1.375rem);
}

.quote-1 {
    composes: heading;
    font-weight: 500;
    line-height: 150%;
    font-size: clamp(1.25rem, 1.1125rem + 0.5312vw, 1.75rem);
}

.body {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: #000000;
    margin: 0;
}

.body-1 {
    composes: body;
    font-size: clamp(1.25rem, 1.2156rem + 0.1328vw, 1.375rem);
}

.body-2 {
    composes: body;
    font-size: 18px;
}

.body-3 {
    composes: body;
    font-size: 16px;
}

.body-4 {
    composes: body;
    font-size: 14px;
    line-height: 150%;
    color: #000000;
}

.label-2 {
    composes: heading;
    font-size: 14px;
    line-height: 120%;
    display: flex;
    align-items: flex-end;
    letter-spacing: 2px;
    color: #000000;
    margin: 0;
}

.description {
    composes: body;
    font-size: clamp(1.125rem, 1.0563rem + 0.2656vw, 1.375rem);
    line-height: 150%;
    color: rgba(0, 0, 0, 0.75);
    text-align: left;
    margin: 0;
}

/* COMMON BUTTON STYLES */
.buttonText {
    composes: heading;
    line-height: 24px;
    letter-spacing: 2px;
    text-align: center;
    color: #000000;
}

.button {
    composes: buttonText;
    border-radius: 14px;
    box-sizing: border-box;
    transition: color 0.2s;
    border-style: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background 0.2s;
    transition: outline 0.1s;
}

.button-1 {
    composes: button;
    font-size: 18px !important;
    height: 84px !important;
}

.button-2 {
    composes: button;
    font-size: 16px !important;
    height: 64px !important;
}

.button-3 {
    composes: button;
    font-size: 14px !important;
    height: 54px !important;
}

.button-4 {
    composes: button;
    font-size: 14px !important;
    height: 44px !important;
}

/* PRIMARY BUTTON STYLES */
.primaryButton {
    composes: button;
    background-color: #DBC6AA;
    transition: background 0.2s;
}

.primaryButton:hover {
    color: #FFFFFF;
    background-color: #1E3B47;
}

.primaryButton:active {
    outline: 6px solid #dee0de;
}

.primaryButton:disabled {
    background-color: rgba(33, 50, 37, 0.15) !important;
    color: rgba(33, 50, 37, 0.25) !important;
}

.primaryButtonDark {
    composes: primaryButton;
}

.primaryButtonDark:hover {
    background-color: #FFFFFF !important;
    color: #000000;
}

.primaryButtonDark:disabled {
    color: rgba(255, 255, 255, 0.15);
    background-color: rgba(255, 255, 255, 0.15);
}

.primaryButtonDark:active {
    outline: 6px solid rgba(255, 255, 255, 0.15) !important;
}

/* SECONDARY BUTTON STYLES */
.secondaryButton {
    composes: button;
    background-color: rgba(33, 50, 37, 0.15);
    backdrop-filter: blur(10px);
    transition: background 0.2s;
}

.secondaryButton:hover {
    background-color: rgba(33, 50, 37, 0.25) !important;
}

.secondaryButton:active {
    outline: 6px solid rgba(33, 50, 37, 0.15) !important;
}

.secondaryButton:disabled {
    background-color: white;
    border: 1px solid rgba(33, 50, 37, 0.15) !important;
    color: rgba(33, 50, 37, 0.25) !important;
}

.secondaryButtonDark {
    composes: button;
    color: white;
    background-color: rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(10px);
    transition: background 0.2s;
}

.secondaryButtonDark:hover {
    color: white;
    background-color: rgba(255, 255, 255, 0.25) !important;
}

.secondaryButtonDark:active {
    outline: 6px solid rgba(255, 255, 255, 0.15) !important;
}

.secondaryButtonDark:disabled {
    background-color: black;
    border: 1px solid 6px solid rgba(255, 255, 255, 0.15) !important;
    color: rgba(255, 255, 255, 0.25) !important;
}

/* LINK BUTTON STYLES */
.linkButton {
    composes: heading;
    font-size: 16px;
    letter-spacing: 1px;
    border-radius: 0;
    height: 44px;
    background-color: white;
    border-bottom: 4px solid #DBC6AA;
    padding: 0
}

.linkButton:hover {
    background-color: white;
    border-bottom: 4px solid #000000;
}

.linkButton:disabled {
    background-color: white;
    color: rgba(33, 50, 37, 0.25);
    border-bottom: 4px solid rgba(33, 50, 37, 0.25);
}