.container {
    composes: container from '../../theme.module.css';
    background-color: #1E3B47;
}

.mainContent {
    composes: mainContent from '../../theme.module.css';
    flex-direction: column;
    align-items: center;
}

.headingContainer {
    display: flex;
    flex-direction: column;
    width: 57%;
    margin-bottom: 72px;
}

.heading {
    composes: heading-2 from '../../theme.module.css';
    color: #FFFFFF;
    text-align: left;
}

.topHeading {
    composes: heading-4 from '../../theme.module.css';
    color: #DBC6AA;
    margin-bottom: 32px;
}

.carouselContainer {
    width: 100% !important;
}

.carousel {
    width: 795px;
    height: 500px;
}

.navigationButton {
    composes: secondaryButtonDark from "../../theme.module.css";
    width: 54px;
    height: 54px;
    border-radius: 12px;
}

.navigationDots {
    z-index: 2;
}

.navigationDots>button {
    width: 10px;
    height: 10px;
    background-color: #0C232D !important;
    border-radius: 50%;
    margin: 4px;
    cursor: pointer;
    border-style: hidden;
    padding: 0;
    transition: background-color .5s ease;
}

.navigationDots>button:disabled {
    width: 12px;
    height: 12px;
    background-color: white !important;
    border-radius: 50%;
    margin: 4px;
    cursor: pointer;
    border-style: hidden;
    padding: 0;
    transition: background-color .5s ease;
}

.testimonialContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 32px;
    width: 100%;
}

.testimonialContainer>button:first-child {
    margin-right: 72px;
}

.testimonialContainer>button:last-child {
    margin-left: 72px;
}

.testimonial {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    min-height: 450px;
    background: #0C232D;
    position: relative;
    margin: 0 24px;
}

.userContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.nameContainer {
    display: flex;
    flex-direction: column;
    margin: 0 0 17px 48px;
    flex-grow: 1;
}

.testimonialImage {
    width: clamp(5.5rem, 4.9286rem + 2.8571vw, 7.5rem);
    max-width: 120px;
}

.testimonialText {
    composes: quote-1 from '../../theme.module.css';
    color: #FFFFFF;
    word-break: keep-all;
    padding: 48px;
    transition: all .5s ease;
}

.testimonialText::before {
    content: url("../../../assets/landing_page/quote.svg");
    position: absolute;
    left: -25px;
    top: 35px;
    transform: rotate(180deg);
}

.testimonialText::after {
    content: url("../../../assets/landing_page/quote.svg");
    position: absolute;
    right: -25px;
    transform: translateY(10px);
}

.testimonialName {
    font-family: 'DM Sans';
    font-weight: 500;
    line-height: 150%;
    color: #FFFFFF;
    font-size: clamp(1.125rem, 1.0906rem + 0.1328vw, 1.25rem);
    margin-top: 0;
    margin-bottom: 8px;
    transition: all .5s ease;
}

.testimonialJobTitle {
    font-family: 'DM Sans';
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: 2px;
    color: rgba(255, 255, 255, 0.5);
    width: 80%;
    margin: 0;
}


@media screen and (max-width: 1080px) {
    .testimonialContainer>button {
        display: none;
    }
}

@media screen and (max-width: 768px) {
    .userContainer {
        flex-direction: column;
        align-items: flex-start;
    }
}

@media (min-width: 320px) and (max-width: 650px) {
    .carousel {
        height: 450px;
    }

    .testimonial {
        min-height: 450px;
        height: fit-content;
    }

    .navigationDots {
        margin-top: 24px;
    }

    .headingContainer {
        width: 90%;
        margin-bottom: 0;
    }

    .topHeading {
        margin-bottom: 24px;
    }

    .testimonialText {
        padding: 24px;
        font-size: unset;
    }

    .nameContainer {
        margin-left: 24px;
    }
}