@keyframes fadein {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.mainLayoutNew {
    display: flex;
    flex-direction: column;
    position: relative;
    box-sizing: border-box;
}

.mainLayout {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    animation: fadein 0.2s ease-in;
    margin-top: 64px;
    position: relative;
    box-sizing: border-box;
}

.mainLayoutWithSubheader {
    margin-top: 115px;
    position: relative;
    box-sizing: border-box;
}

.footer {
    position: relative;
}