.background {
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top;
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 30%;
    margin-top: 50px;
}

.heading {
    color: #4F4F4F;
    font-weight: 900;
    font-size: clamp(1.25rem, 1.0782rem + 0.664vw, 1.875rem);
    margin-bottom: 20px;
    text-align: center;
}

.image {
    height: 300px;
}

.message {
    font-weight: 400;
    font-size: clamp(0.9375rem, 0.8516rem + 0.332vw, 1.25rem);
    margin-top: 20px;
    text-align: center;
}

.videoButton {
    margin-top: 16px;
    width: 60%;
}

@media (max-width: 1279px) {
    .content {
        max-width: 60%;
    }

    .image {
        height: 250px;
    }
}

@media (max-width: 959.95px) {
    .content {
        max-width: 90%;
    }

    .image {
        height: 200px;
    }
}