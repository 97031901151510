/* Bid accept card */
.detailsItemValue {
    padding-left: 35px,
}

.card {
    padding: 8px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    border: 1px solid #eee;
}

.cardHeading {
    padding-bottom: 16px;
}

.cardContent {
    margin-top: 8px;
}