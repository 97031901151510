.modal {
  width: 55%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%);
  padding: 16px 8px 24px;
}

@media (max-width: 959.95px) {
  .modal {
    width: calc(100% - 10px);
  }
}

.modalContainer {
  font-family: "DM Sans";
  font-weight: 500;
  width: calc(100% - 20px);
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  justify-content: space-between;
  box-sizing: border-box;
}

.modalContainer > button {
  margin-bottom: 24px;
}

.googleBtnContainer {
  width: 100%;
  margin-bottom: 16px;
}

.modalHeading {
  font-weight: 700;
  font-size: 40px;
  color: #4f4f4f;
  margin-bottom: 30px;
}

.modalFooter {
  color: #1e3b47;
  text-align: center;
  font-weight: 800;
}

.modalFooter > a {
  text-decoration: underline;
  cursor: pointer;
}

.closeButton {
  text-align: center;
  position: absolute;
  top: 32px;
  right: 32px;
  cursor: pointer;
}

.registerTypeButton {
  margin-bottom: 24px;
}
