.container {
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 24px;
    background: #FFFFFF;
    border-radius: 16px 0px 0px 16px;
    width: 588px;
}

.smallText {
    composes: body-3 from '../../theme.module.css';
    color: rgba(33, 50, 37, 0.5);
}

.tabs {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(33, 50, 37, 0.15);
}

.tabButton {
    composes: linkButton from '../../theme.module.css';
    border-bottom: none;
    padding: 16px 0 24px 0;
    width: 33.3333%;
    min-width: 130px;
    transition: background-color 0.15s ease-in;
    line-height: 1.1 !important;
    height: 36px !important;
}

.tabButton:hover {
    border-bottom: 2px solid #1E3B47;
}

.tabButton.active {
    border-bottom: 2px solid #DBC6AA;
    transition: background-color 0.15s ease-in;
}

.mainText {
    composes: heading-5 from '../../theme.module.css';
    font-size: clamp(1rem, 0.8969rem + 0.3984vw, 1.375rem);
    margin-bottom: 24px;
}

.mainContent {
    width: 100%;
    display: grid;
    grid-template-columns: auto max-content;
    padding: 32px 0 24px 0;
    flex-grow: 1;
}

.locationSearch {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.locationSearchBusiness {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.locationSearchBusiness>p {
    margin-bottom: 0;
    margin-right: 24px;
}

.priceSelector {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.price {
    align-items: center;
    padding: 12px 0px;
    width: 100%;
    height: 26px;
    border-radius: 14px;
    composes: heading-5 from '../../theme.module.css';
    font-size: clamp(1.375rem, 1.2719rem + 0.3984vw, 1.75rem);
    text-align: center;
    color: #1E3B47;
}

.primaryButton {
    composes: button-2 from '../../theme.module.css';
    composes: primaryButton from '../../theme.module.css';
    height: 56px;
}

.addressField {
    width: 300px;
}

@media (max-width: 1196px) {
    .container {
        border-radius: 0;
    }
}

@media (max-width: 959.95px) {
    .container {
        width: 100%;
    }

    .mainContent {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 16px 0;
    }

    .tabButton {
        padding-bottom: 24px;
        min-width: unset;
    }

    .locationSearch {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-top: 16px;
    }

    .priceSelector {
        width: 100%;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    .mainText {
        margin-bottom: 0;
    }

    .priceSelector {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    .price {
        margin-top: 0;
        background-color: white;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        text-align: right;
        padding: 15px 10px;
        width: 156px;
    }

    .addressField {
        width: 180px;
    }
}