.container {
    composes: container from '../../theme.module.css';
    background-color: #F1F1F1;
}

.mainContent {
    composes: mainContent from '../../theme.module.css';
    box-sizing: border-box;
}

.header {
    composes: heading-2 from '../../theme.module.css';
    padding-bottom: 32px;
    width: 50%;
}

.description {
    composes: description from '../../theme.module.css';
    width: 85%;
}

.smallHeader {
    composes: heading-4 from '../../theme.module.css';
    margin-bottom: 16px;
}

.listContainer {
    padding: 0px 32px;
    width: 50%;
}

.listItem {
    display: flex;
    flex-direction: column;
    padding: 32px 0px;
    border-top: 1px solid rgba(33, 50, 37, 0.15);
}

.listItem>div:first-child {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
}

.listNumber {
    font-family: 'DM Sans';
    font-weight: 700;
    font-size: 46px;
    line-height: 120%;
    color: rgba(33, 50, 37, 0.25);
    width: 20%;
    text-align: right;
    padding-left: 24px;
}

@media only screen and (max-width: 959.95px) {
    .container {
        padding: 72px 24px;
    }

    .mainContent {
        flex-direction: column;
        align-items: center;
    }

    .header {
        width: 100%;
    }

    .listContainer {
        width: 100%;
    }

    .description {
        width: 100%;
    }
}

@media (min-width: 768px) and (max-width: 1440px) {
    .listNumber {
        font-size: 28px;
    }
}

@media (min-width: 320px) and (max-width: 768px) {
    .listNumber {
        font-size: 26px;
    }
}