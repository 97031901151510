.container {
    composes: container from '../../theme.module.css';
    background-image: url("../../../assets/landing_page/logo-b-large.svg");
    background-repeat: no-repeat;
    background-position: bottom;
    background-color: #F1F1F1;
}

.mainContent {
    composes: mainContent from '../../theme.module.css';
    justify-content: space-between;
}

.mainContent>div:first-child {
    padding: 0 32px 0 0;
}

.mainContent>div {
    width: 45%;
}

.heading {
    composes: heading-2 from '../../theme.module.css';
}

.description {
    composes: body-2 from '../../theme.module.css';
    color: rgba(0, 0, 0, 0.75);
    padding: 32px 0 48px 0;
}

.secondaryButton {
    composes: button-2 from '../../theme.module.css';
    composes: secondaryButton from "../../theme.module.css";
    width: 325px
}

.listHeader {
    composes: body-2 from '../../theme.module.css';
    letter-spacing: 2px;
    color: rgba(33, 50, 37, 0.5);
    margin: 10px 0 24px 0;
}

.textContainer {
    display: flex;
    flex-direction: column;
}

.resourceContainer {
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    border-radius: 16px;
    padding: 32px;
    box-sizing: border-box;
}

.resource {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding: 24px 0px;
    border-top: 1px solid rgba(33, 50, 37, 0.15);
    cursor: pointer;
}

.resourceCategory {
    font-size: 14px;
}

.resourceTitle {
    font-size: 20px;
    padding-top: 8px;
}

.imgIcon {
    height: 50px;
    width: 50px;
    border-radius: 100px;
    filter: grayscale(1);
}

.resource:hover {
    cursor: pointer;
    opacity: 0.6;
}




@media (min-width: 320px) and (max-width: 768px) {
    .secondaryButton {
        align-self: center;
    }
}

@media (max-width: 959.95px) {
    .container {
        padding: 72px 24px;
    }

    .mainContent {
        display: block !important;
    }

    .mainContent>div:first-child {
        margin-top: 32px;
    }

    .mainContent>div {
        width: 100%;
    }

    .resourceContainer {
        padding: 16px;
    }

    .secondaryButton {
        align-self: center;
    }
}