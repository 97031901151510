.container {
    background-color: #1E3B47;
}

.content {
    min-height: 100vh;
    display: flex;
    flex-basis: 50%;
    justify-content: space-between;
}

.leftPane {
    width: 50%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1E3B47;
    background-image: url("../../../assets/landing_page/logo-bg-dark.svg");
    background-repeat: no-repeat;
    background-position: right;
    background-position-y: 100%;
}

.rightPane {
    width: 50%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.providerPortal {
    background-image: url("../../../assets/landing_page/background-provider.jpg");
}

.aboutUs {
    background-image: url("../../../assets/landing_page/background-about.jpg");
}

.sustainability {
    background-image: url("../../../assets/landing_page/background-sustainability.jpg");
}

.pitchContent {
    width: 70%;
    padding: 80px 76px;
    display: flex;
    flex-direction: column;
    max-width: 700px;
}

.pitchContent>p {
    margin: 0 0 16px 0;
}

.heroText {
    composes: heading-1 from '../../theme.module.css';
    color: #FFFFFF;
    margin: 24px 0;
}

.subText {
    composes: body-1 from '../../theme.module.css';
    color: rgba(255, 255, 255, 0.85);
}

.buttonContainer {
    display: flex;
    align-items: center;
    margin: 32px 0 0 0;
}

.buttonContainer>button:first-child {
    margin-right: 24px;
}

.primaryButton {
    composes: button-2 from '../../theme.module.css';
    composes: primaryButtonDark from "../../theme.module.css";
    width: 170px;
}

.secondaryButtonDark {
    composes: button-2 from '../../theme.module.css';
    composes: secondaryButtonDark from "../../theme.module.css";
    width: 200px;
}

@media (min-width: 1921px) {
    .pitchContent {
        width: 50%;
    }

    .leftPane {
        justify-content: flex-end;
    }
}

@media (min-width: 1440px) and (max-width: 1920px) {
    .pitchContent {
        width: 65%;
    }

    .leftPane {
        justify-content: flex-end;
    }
}

@media only screen and (max-width: 768px) {
    .content {
        flex-direction: column;
        height: 100%;
        padding-top: 60px;
    }

    .leftPane {
        width: 100%;
        height: 50vh;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
        background-image: none;
    }

    .rightPane {
        width: 100%;
        height: 300px;
        background-position-y: 95%;
    }

    .pitchContent {
        padding: 80px 0;
        width: 100%;
    }

    .heroText {
        padding: 0 24px;
    }

    .subText {
        padding: 0 24px;
    }

    .primaryButton {
        margin: 24px 24px 0 24px;
    }
}

@media (min-width: 320px) and (max-width: 390px) {
    .leftPane {
        height: 70vh;
    }

    .leftPane.highLeft {
        height: 77vh;
    }
}

@media (min-width: 391px) and (max-width: 768px) {
    .leftPane {
        height: 63vh;
    }
}

@media (min-width: 320px) and (max-width: 768px) {
    .buttonContainer {
        width: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 0;
    }

    .buttonContainer>button:first-child {
        margin-bottom: 16px;
    }

    .primaryButton {
        align-self: center;
    }
}

@media only screen and (max-height: 768px) {
    .leftPane {
        justify-content: center;
        height: unset;
    }
}