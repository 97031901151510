.container {
    composes: container from '../../theme.module.css';
}

.mainContent {
    composes: mainContent from '../../theme.module.css';
}



.header {
    composes: heading-2 from '../../theme.module.css';
    padding-bottom: 32px;
}

.description {
    composes: body-2 from '../../theme.module.css';
    color: rgba(0, 0, 0, 0.75);
}

.feature {
    display: flex;
    padding: 24px 0;
    border-top: 1px solid rgba(33, 50, 37, 0.15);
}

.feature:hover {
    cursor: pointer;
    opacity: 0.6;
}

.hero {
    display: flex;
    flex-direction: column;
    width: 45%;
    padding: 0 32px 0 0;
}

.resourceContainer {
    width: 45%;
    padding: 32px;
}

.accordion {
    border-radius: 0;
    box-shadow: none;
}

.accordion::before {
    background-color: white;
}

.accordionHeader {
    font-family: 'DM Sans';
    font-weight: 700;
    font-size: 22px;
    line-height: 120%;
    padding-right: 16px;
}

.primaryButton {
    composes: primaryButton from "../../theme.module.css";
    background-color: white !important;
}

.primaryButton>.MuiTouchRipple-root {
    display: none;
}

.primaryButton:hover {
    color: black !important;
}

.primaryButton:active {
    outline: none
}

.imgIcon {
    height: 50px;
    width: 50px;
    border-radius: 100px;
    filter: grayscale(1);
}

.customAccordion {
    width: 100%;
    display: grid;
    grid-template-columns: auto max-content;
    box-sizing: border-box;
    padding: 10px 16px;
    line-height: 120%;
}

.accordionSummary p,
.accordionDetails p {
    padding: 0px 0px;
}

.link {
    display: block;
    margin-top: 16px;
    font-family: Avenir, Nunito Sans, Open Sans, Helvetica, sans-serif;
}

@media (max-width: 959.95px) {
    .container {
        padding: 24px;
    }

    .hero {
        width: 100%;
    }

    .resourceContainer {
        width: 100%;
        padding: 32px 0;
    }

    .mainContent {
        flex-wrap: wrap;
    }
}