.container {
    composes: container from '../../theme.module.css';
    background-image: url("../../../assets/landing_page/logo-bl-large.svg");
    background-repeat: no-repeat;
    background-position: bottom;
    background-position-x: 0;
    background-color: #F1F1F1;
}

.mainContent {
    composes: mainContent from '../../theme.module.css';
}

.image {
    width: 50%;
    min-width: 435px;
    min-height: 800px;
    filter: drop-shadow(0px 40px 60px rgba(0, 0, 0, 0.05));
    border-radius: 16px;
}

.header {
    composes: heading-2 from '../../theme.module.css';
    padding-bottom: 32px;
}

.featureContainer {
    padding: 0 72px;
}

.feature {
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;
    padding: 32px 0px 32px;
    border-top: 1px solid rgba(33, 50, 37, 0.15);
}

.feature>img {
    margin-right: 16px;
}

.featureHeader {
    composes: heading-4 from '../../theme.module.css';
}

.featureDescription {
    composes: body-2 from '../../theme.module.css';
    color: rgba(0, 0, 0, 0.75);
    margin-top: 8px;
}

.primaryButton {
    composes: button-2 from '../../theme.module.css';
    composes: primaryButton from '../../theme.module.css';
    width: 171px;
}

@media (max-width: 959.95px) {
    .container {
        background-image: none;
    }

    .mainContent {
        flex-direction: column;
        align-items: center;
    }

    .featureContainer {
        padding: 0;
    }

    .header {
        margin-top: 48px;
    }

    .image {
        width: 100%;
        min-width: unset;
        min-height: unset;
    }
}

@media (min-width: 320px) and (max-width: 768px) {
    .primaryButton {
        align-self: center;
        width: 100%;
    }
}