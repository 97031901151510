.container {
    composes: container from '../../theme.module.css';
    padding: 120px 0;
    background: #1E3B47;
}

@media (min-width: 391px) and (max-width: 768px) {
    .container {
        padding: 72px 0 !important;
    }
}


.mainContent {
    composes: mainContent from '../../theme.module.css';
    width: 100% !important;
    overflow-x: auto;
}


.mainContent::-webkit-scrollbar {
    display: none;
}

.mainContent {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.quote {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 32px;
    margin-right: 16px;
    gap: 24px;
    min-width: 272px;
    width: clamp(17rem, 15.0757rem + 7.4369vw, 24rem);
    height: clamp(13.625rem, 13.0065rem + 2.3904vw, 15.875rem);
    background: #0C232D;
}

.contentText {
    composes: body-1 from '../../theme.module.css';
    color: #FFFFFF;
}