.root {
    padding: 0 0 16px;
    min-height: 100vh;
}


.task {
    font-size: 0.9rem;
    font-weight: 500;
}

.title {
    display: flex;
    justify-content: space-between;
}

.step {
    font-size: 1.7rem;
    font-weight: 800;
}

.cardTitle {
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 16px;
    padding: 10px 0;
}

@media (max-width: 959.95px) {
    .cardTitle {
        font-size: 1rem;
    }

    .sideBar {
        top: 0;
        position: sticky;
        z-index: 10;
    }

    .content {
        margin-top: 24px;
        padding: 0 16px;
    }
}

@media (min-width: 959.95px) {
    .step {
        font-size: 2.75rem;
    }

    .task {
        font-size: 1.4rem;
    }

    .content {
        padding-left: 64px;
        flex: 1;
    }

    .sideBar {
        padding-right: 64px;
        margin-left: 40px;
        width: 390px;
    }

    .root {
        padding: 64px 0;
        display: flex;
        flex-direction: row-reverse;
        align-items: flex-start;
    }
}