.root {
  min-height: calc(100vh - 64px);
  position: relative;
  display: grid;
  grid-template-columns: auto 400px;
  column-gap: 80px;
}

.container {
  width: 100%;
  position: relative;
}

.section {
  width: 100%;
  padding: 24px 0;
  border-top: 1px solid #ccc;
}

.sectionTitle {
  font-size: 16px;
  font-weight: 800;
  padding: 16px;
}

.header {
  display: flex;
  align-items: flex-start;
}

.headerText {
  line-height: 1.2;
  margin-bottom: 8px;
  font-size: 28px;
  font-weight: 800;
  color: #4f4f4f;
}

.backBtn {
  color: #1E3B47;
  margin-right: 8px;
}

.backBtn:hover {
  color: #0C232D;
}

.headerSubtext {
  margin-bottom: 8px;
  font-size: 16px;
  line-height: 1.2;
  color: #8f8f8f;
  font-weight: 600;
}

.detailsContainer {
  width: 100%;
  display: block;
  padding: 8px 16px;
}

.block {
  display: block;
}

.cardWrapper {
  width: 100%;
  margin-top: 60px;
}

.map {
  height: 400px;
  width: 100%;
  padding-bottom: 80px;
}

@media (max-width: 1300px) {
  .root {
    grid-template-columns: auto;
    padding: 8px;
    margin-top: 24px;
  }

  .header {
    font-size: 27px;
  }

  .headerText {
    font-size: 24px;
    margin-left: 8px;
  }

  .headerSubtext {
    font-size: 15px;
    margin-left: 8px;
  }

  .backBtn {
    font-size: 30px;
  }

  .titleContainer {
    margin: unset;
  }

  .detailsContainer {
    padding: 16px 0;
  }

  .cardWrapper {
    border-top: 1px solid #ccc;
    padding-top: 16px;
  }

  .map {
    margin-top: 10px;
  }
}

@media (min-width: 600px) {
  .root {
    padding: 48px;
  }

  .detailsContainer {
    margin: 0 20px;
  }
}
