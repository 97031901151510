.container {
    composes: container from '../../theme.module.css';
    background-color: #F1F1F1;
    background-image: url("../../../assets/landing_page/logo-bottom-left.svg");
    background-repeat: no-repeat;
    background-position: bottom;
    background-position-x: 0;
}

.mainContent {
    composes: mainContent from '../../theme.module.css';
    flex-direction: column;
}

.mainContent>.description {
    font-size: 18px;
    text-align: center;
    line-height: 150%;
    color: rgba(0, 0, 0, 0.75);
}

.heading {
    composes: heading-2 from '../../theme.module.css';
    text-align: center;
    margin-bottom: 24px;
}

.tableContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px
}

.fixedColumnTable {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.fixedColumnTable::-webkit-scrollbar {
    display: none;
}

.fixedColumnTable {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.whyDimensionTable {
    border-spacing: 0;
    table-layout: fixed;
}

.whyDimensionTable td:first-child,
.whyDimensionTable th:first-child {
    position: sticky;
    position: -webkit-sticky;
    left: 0;
    background-color: #F1F1F1;
    text-align: left;
    z-index: 1;
    width: 20%;
}

.whyDimensionTable td:nth-child(2),
.whyDimensionTable th:nth-child(2) {
    padding-left: 32px;
}

.whyDimensionTable td,
.whyDimensionTable th {
    font-family: 'DM Sans';
    font-weight: 700;
    font-size: clamp(1rem, 0.9313rem + 0.2656vw, 1.25rem);
    line-height: 120%;
    border-bottom: 1px solid rgba(33, 50, 37, 0.15);
    padding: 24px;
    text-align: center;
}

.whyDimensionTable th {
    letter-spacing: 2px;
    color: rgba(33, 50, 37, 0.5);
}

.buttonContainer {
    display: flex;
    align-self: flex-end;
    margin-top: 48px;
}

.secondaryButton {
    composes: button-2 from '../../theme.module.css';
    composes: secondaryButton from "../../theme.module.css";
    width: 265px;
    margin-right: 16px;
}

.primaryButton {
    composes: button-2 from '../../theme.module.css';
    composes: primaryButton from "../../theme.module.css";
    width: 170px;
}

.cardHeading {
    font-size: clamp(1.25rem, 1.2156rem + 0.1328vw, 1.375rem);
    text-align: left;
    padding-top: 24px;
    padding-bottom: 16px;
    margin-bottom: 0;
}


.featureContainer {
    width: 100%;
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(auto-fill, minmax(285px, 1fr));
    margin-top: 72px;
}

.feature {
    display: flex;
    flex-direction: column;
    padding: 24px;
}

.description {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: rgba(0, 0, 0, 0.75);
    max-width: 650px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

@media (max-width: 940px) {

    .whyDimensionTable td:first-child,
    .whyDimensionTable th:first-child {
        -webkit-box-shadow: 6px 0px 5px -1px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 6px 0px 5px -1px rgba(0, 0, 0, 0.1);
        box-shadow: 6px 0px 5px -1px rgba(0, 0, 0, 0.1);
    }
}

@media (min-width: 960px) {

    .whyDimensionTable td:nth-child(2),
    .whyDimensionTable th:nth-child(2) {
        padding-left: 0;
    }
}

@media (min-width: 768px) and (max-width: 959.95px) {

    .whyDimensionTable td:nth-child(2),
    .whyDimensionTable th:nth-child(2) {
        padding-left: 265px;
    }
}

@media screen and (max-width: 767px) {
    .buttonContainer {
        align-self: center !important;
        align-items: center;
    }

    .buttonContainer>button {
        margin: 0;
    }

    .primaryButton {
        width: 265px;
    }

    .whyDimensionTable td:nth-child(2),
    .whyDimensionTable th:nth-child(2) {
        padding-left: 525px;
    }
}

@media (max-width: 959.95px) {
    .container {
        padding: 24px;
    }

    .featureContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .feature {
        width: 100%;
    }

    .buttonContainer {
        flex-direction: column;
        align-self: flex-start;
    }

    .buttonContainer>button:first-child {
        margin-bottom: 16px;
    }
}