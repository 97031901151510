.container {
    composes: container from '../../theme.module.css';
    background-image: url("../../../assets/landing_page/logo-right.svg");
    background-repeat: no-repeat;
    background-position: right;
    background-position-y: top;
    background-color: #F1F1F1;
}

.mainContent {
    composes: mainContent from '../../theme.module.css';
    flex-direction: column;
}

.header {
    composes: heading-2 from '../../theme.module.css';
    width: 70%;
    margin: 0;
}

.subHeader {
    composes: heading-4 from '../../theme.module.css';
    padding: 32px 0;
    width: 70%;
    margin: 0;
}

.description {
    font-family: "DM Sans";
    font-weight: 400;
    font-size: clamp(1.125rem, 1.0563rem + 0.2656vw, 1.375rem);
    line-height: 150%;
    color: rgba(0, 0, 0, 0.75);
    width: 70%;
    margin: 0;
}

.boxContainer {
    display: flex;
    margin-top: 72px;
    justify-content: space-between;
}

.box {
    display: flex;
    width: 588px;
    max-width: 50%;
    flex-direction: column;
    box-sizing: border-box;
    background: #FFFFFF;
    border-radius: 16px;
    margin: 0 16px;
}

.boxContent {
    display: flex;
    flex-direction: column;
    padding: 32px 32px 0 32px;
    flex-grow: 1;
}

.boxImage {
    border-radius: 16px 16px 0 0;
    max-height: 310px;
    object-fit: cover;
}

.boxHeading {
    width: 100%;
    padding: 0;
}

.boxList {
    padding: 0 30px;
}

.boxList>li {
    font-family: "DM Sans";
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    color: rgba(0, 0, 0, 0.75);
}

.boxList>li::marker {
    content: url("../../../assets/landing_page/list-item.svg");
}

.primaryButton {
    composes: button-2 from "../../theme.module.css";
    composes: primaryButton from "../../theme.module.css";
    margin: 48px 32px 32px 32px;
    justify-self: flex-end;
}

@media (min-width: 1440px) {
    .mainContent {
        align-items: center;
    }

    .header {
        width: 85%;
        text-align: left;
    }

    .subHeader {
        width: 85%;
        text-align: left;
    }

    .description {
        width: 85%;
        text-align: left;
    }

    .boxContainer {
        justify-content: center;
    }

    .boxContainer>div.box:first-child {
        margin-right: 16px;
    }
}

@media (max-width: 959.95px) {
    .container {
        padding: 72px 24px;
    }

    .header {
        width: 100%;
    }

    .subHeader {
        width: 100%;
    }

    .description {
        width: 100%;
    }

    .boxContainer {
        flex-direction: column;
        align-items: center;
    }

    .box {
        width: 100%;
        max-width: 100%;
        margin-bottom: 32px;
    }

    .boxList>li {
        margin-bottom: 0;
    }
}