.form {
  margin: 40px 0px;
}

.registerBtn {
  background-color: #1e3b47;
  color: #fff;
  width: 100%;
}

.registerBtn:hover {
  background-color: #0c232d;
}

.textField {
  margin-right: 8px;
  width: 100%;
}

.rowContainer {
  margin-bottom: 10px;
}

@media (min-width: 959.95px) {
  .registerBtn {
    width: 250px;
  }

  .textField {
    margin-bottom: 10px;
  }
}
