.container {
  composes: container from "../../theme.module.css";
  flex-direction: column;
  justify-content: space-between;
  padding: 48px 120px;
  min-height: 100px;
  background: #0c232d;
  border-top: 1px solid rgba(255, 255, 255, 0.15);
}

@media (min-width: 391px) and (max-width: 768px) {
  .container {
    padding: 48px 32px !important;
  }
}

@media (min-width: 320px) and (max-width: 390px) {
  .container {
    padding: 48px 24px !important;
  }
}

.mainContent {
  composes: mainContent from "../../theme.module.css";
  justify-content: space-between;
}

.logoContainer {
  display: flex;
  flex-direction: column;
  margin-right: 32px;
}

.logoContainer>img {
  align-self: flex-start;
  margin-bottom: 32px;
}

.logoContainer>span.hrefsColumnHeader {
  margin-bottom: 16px !important;
  align-self: flex-start;
}

.logoContainer>p.addressText {
  text-align: left !important;
  margin-top: 0;
}

.hrefsContainer {
  display: flex;
  width: 70%;
  justify-content: flex-end;
}

.hrefsContainerGroup {
  display: flex;
}

.hrefsColumn {
  display: flex;
  flex-direction: column;
  max-width: 180px;
}

.hrefsColumnHeader,
.hrefsColumnItem,
.addressText {
  justify-content: flex-start;
  text-align: left;
}

.hrefsColumnHeader {
  composes: button-3 from "../../theme.module.css";
  margin-bottom: 24px;
  height: 17px !important;
  color: rgba(255, 255, 255, 0.5);

}

.hrefsColumnItem {
  composes: button-3 from "../../theme.module.css";
  margin-bottom: 16px;
  color: #ffffff;
  cursor: pointer;
  text-decoration: none;
  margin: 0;
  line-height: 1.35;
}

.addressText {
  composes: body-4 from "../../theme.module.css" !important;
  margin-bottom: 16px;
  color: #ffffff;
  cursor: pointer;
  text-decoration: none;
  margin: 0;
}

.bottomContent {
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  font-weight: 400;
  font-size: 14px;
  margin-top: 24px;
  max-width: 1440px;
}

.trustBox {
  display: flex;
  justify-content: center;
  height: 84px;
  align-items: center;
}



@media only screen and (max-width: 959.95px) {
  .container {
    padding: 48px 0;
  }
}

@media only screen and (max-width: 768px) {


  .mainContent {
    flex-direction: column;
    align-items: flex-start;
  }

  .hrefsColumn {
    margin: 0 16px 32px 0;
  }

  .hrefsContainer {
    width: 100%;
    margin-top: 48px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 0.5fr);
    gap: 16px;
  }
}

.hrefsContainer>div.hrefsColumn:not(:last-child) {
  margin-right: 0;
}

.hrefsContainerGroup {
  flex-wrap: wrap;
}

.bottomContent {
  flex-direction: column;
}

.bottomContent>span {
  margin-bottom: 16px;
}

@media only screen and (min-width: 769px) {
  .hrefsContainer>div.hrefsColumn:not(:last-child) {
    margin-right: 40px;
  }
}