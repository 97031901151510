.desktopRoot {
    display: flex;
    flex-direction: column;
}

.desktopRootMobile {
    margin-bottom: 1rem;
}

.detailsContainer {
    padding: 16px;
}

.detailsGridContainer {
    padding: 10px 0px;
    align-items: start;
    display: grid;
}

.detailsGridContainerColumn {
    display: flex;
    flex-direction: column;
    padding: 10px 0px;
}

.detailsGridItem {
    align-items: center;
    display: flex;
}

.detailsItemTitle {
    font-weight: bold;
    padding-left: 10px;
}

.detailsProductItemDescription {
    padding-left: 10;
    opacity: 0.7;
}

.detailsItemValue {
    padding-left: 35px;
}

.image {
    height: 150;
    width: 100%;
    object-fit: cover;
    margin-bottom: 20;
}

.imageBox {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    column-gap: 20;
    width: auto;
    max-width: 100%;
    justify-content: flex-start;
}

.productItem {
    display: grid;
    grid-template-columns: 60px auto;
}

.warriorContactContainer {
    display: grid;
    grid-template-columns: 50px auto;
    column-gap: 16
}

.warriorContactAvatar {
    border: 2px solid #459F65;
}

.sectionHeader {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.tabsContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.quoteSelectorTabsRoot {
    min-height: 33px;
    padding: 0 24px;
    max-width: 980px;
}

.quoteSelectorTab {
    font-size: 1.1rem;
    padding: 0 12px;
    min-height: 40px;
    width: 100px;
    min-width: 90px;
}

.enabledTabText {
    color: #459F65;
}

.disabledTabText {
    color: rgba(0, 0, 0, 0.54);
}

.confirmAllButton {
    text-decoration: none;
    height: 30px;
    width: max-content;
}

.confirmButton {
    align-self: flex-end;
    margin-top: 16px;
}

/* Mobile Styles */

.countHeader {
    display: flex;
    padding: 0 8px 8px 10px;
    align-items: center;
    justify-content: space-between;
}

.cardContainer {
    display: flex;
    flex-direction: column;
    cursor: pointer;
}

.quoteCard {
    box-sizing: border-box;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    margin-bottom: 16px;
}

.quoteCard:hover {
    border-color: #459F65;
}

.quoteCardContent {
    display: flex;
    flex-wrap: nowrap;
}

.quoteCardChip {
    width: 90px;
    margin-top: 4px;
}

.quoteCardMainContent {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
}

.cardHeader {
    display: flex;
}

.cardHeader>* {
    margin: 0 4px;
}

.cardDetails {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    margin: 2px 4px;
}

.cardAddress {
    color: #0000004D;
}

.quoteCardButton {
    display: flex;
    align-items: center;
}

.bottomBar {
    display: flex;
    justify-content: space-between;
    background-color: white;
    margin-top: 16px;
    padding: 16px;
    width: 95%;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 5;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
}

@media (min-width: 959.95px) {
    .detailsGridContainer {
        grid-template-columns: 200px auto;
    }
}

@media (max-width: 959.95px) {
    .detailsItemTitle {
        margin-bottom: 4px;
    }

    .detailsItemValue {
        color: #459F65;
    }
}