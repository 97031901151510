.root {
    padding: 32px;
    min-height: 100vh;
}

.header {
    margin: 32px 16px 16px 16px;
    display: flex;
}

.headerTitle {
    display: flex;
    flex-grow: 1;
}

.backBtn {
    height: 30px;
    margin-right: 8px;
    color: #1E3B47;
    cursor: pointer;
}

.backBtn:hover {
    color: #0C232D;
}

.backBtnText {
    font-size: 22px;
    font-weight: bold;
    cursor: pointer;
}

.cardsContainer {
    flex-grow: 1;
    padding: 16px 24px;
    border-radius: 8px;
}

.listContainer {
    display: grid;
    gap: 24px;
    grid-template-columns: repeat(auto-fill, minmax(370px, 1fr));
}

.contentContainer {
    display: flex;
    justify-items: center;
    padding: 16px 24px;
    min-height: 500px;
}

.filterBar {
    display: flex;
    flex-direction: column;
    min-width: 20%;
    padding: 16px 24px;
    border-radius: 8px;
    margin-right: 24px;
}

.cardMobile {
    position: sticky;
    bottom: 0;
}

.filterIconContainer {
    margin-right: 8px;
}

.noJobsDescription {
    text-align: center;
    margin: 40px 0;
}

.titleIcon {
    margin-right: 8px;
}

.loader {
    text-align: center;
    margin: 50px 0;
}


@media (min-width: 959.95px) {
    .filterIconContainer {
        display: none;
    }
}

@media (max-width: 959.95px) {
    .cardMobile {
        display: none;
    }

    .filterBar {
        width: 100%;
    }

    .contentContainer {
        background-color: #fff;
        padding: 8px;
    }

    .cardsContainer {
        padding: 0;
    }

    .listContainer {
        grid-template-columns: auto;
    }

    .root {
        padding: 16px 0;
    }

    .headerTitle {
        margin-left: 24px;
    }
}