.container {
    composes: container from '../../theme.module.css';
    background-color: #0C232D;
}

.mainContent {
    composes: mainContent from '../../theme.module.css';
    flex-direction: column;
    align-items: center;
}

.containerB2b {
    background-color: white;
    text-align: center;
}

.heading {
    composes: heading-4 from '../../theme.module.css';
    text-align: center;
    color: #FFFFFF;
    padding-bottom: 48px;
}

.headingB2b {
    font-size: clamp(2.125rem, 1.6096rem + 1.992vw, 4rem);
    color: black;
    padding-bottom: 32px;
}

.description {
    font-size: clamp(1.125rem, 1.0563rem + 0.2656vw, 1.375rem);
    margin-bottom: 72px;
}

.logoContainer {
    display: flex;
    width: 90%;
    max-width: 1440px;
    overflow-x: scroll;
}

.logoContainer::-webkit-scrollbar {
    display: none;
}

.logoContainer {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.logo {
    padding: 0px;
    width: 500px;
    fill-opacity: 0;
}

.logoDark {
    width: 100%;
    height: 50px;
    margin-top: 15px;
    padding-left: 40px;
    filter: brightness(0) invert(1);
}

.logoNormal {
    width: 100%;
    height: 50px;
    margin-top: 15px;
    padding-left: 40px;
    filter: brightness(0);
    opacity: 0.8;
}

.logoLight {
    padding: 0px;
    width: 500px;
    fill-opacity: 0;
}

@media only screen and (max-width: 1159px) {
    .container {
        padding: 80px 0;
    }

    .logoContainer {
        width: 100%;
    }

    .description {
        margin-bottom: 24px;
    }
}