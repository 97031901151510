.header {
    composes: heading-2 from '../../theme.module.css';
}

.subHeader {
    composes: heading-4 from '../../theme.module.css';
    padding: 32px 0;
}

.description {
    composes: body-2 from '../../theme.module.css';
    margin-top: 16px;
}

.featuresWrapper {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    max-width: 1440px;
}

.featuresColumnLeft {
    display: flex;
    width: 50%;
    flex-direction: column;
    align-items: flex-start;
}

.content {
    padding: 100px 120px;
    height: 91vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    transition: background-color 1000ms linear;

}

.content>button {
    margin-top: 32px;
}

.featuresColumnRight {
    position: sticky;
    left: auto;
    top: 100px;
    right: 0;
    display: flex;
    width: 50%;
    height: 92vh;
    align-items: center;
}

.featureIllustrationWrapper {
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: flex-end;
    align-items: center;
    transition: opacity 0.7s ease-in-out;
}

.rightImage {
    position: absolute;
    height: 91vh;
    width: 100%;
    object-fit: cover;
    -webkit-transition: all 0.7s ease-in-out;
    -moz-transition: all 0.7s ease-in-out;
    transition: all 0.7s ease-in-out;
}

.contentContainerMobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.contentMobile {
    padding: 0 0 24px 0 !important;
    height: unset;
    width: 100%;
}

.rightImageMobile {
    position: relative;
    height: 50% !important;
    width: 100%;
    margin-bottom: 24px;
    object-fit: contain;
}

@media only screen and (max-width: 768px) {
    .header {
        margin: 10px 0;
        width: 90%;
        align-self: center;
    }

    .subHeader {
        margin: 10px 0;
        width: 90%;
        align-self: center;
    }

    .description {
        margin: 10px 0;
        width: 90%;
        align-self: center;
    }
}

@media only screen and (min-width: 1441px) {
    .content {
        height: 840px;
    }

    .featuresColumnRight {
        top: 0;
        bottom: 0;
        margin: auto 0;
        height: 100vh;
    }

    .rightImage {
        height: 840px;
        border-radius: 6px;
    }
}

@media (min-height: 767px) and (max-height: 1080px) {
    .content {
        padding: 100px 80px 100px 20px;
    }

    .featuresColumnRight {
        top: 18%;
        height: 660px;
    }

    .rightImage {
        height: 660px;
    }
}