.container {
    composes: container from '../../theme.module.css';
    background: #F1F1F1;
    background-image: url("../../../assets/landing_page/logo-bg.svg");
    background-repeat: no-repeat;
    background-position: right;
    background-position-y: 0;
    background-size: 25%;
}

.mainContent {
    composes: mainContent from '../../theme.module.css';
    flex-direction: column;
}

.heading {
    composes: heading-2 from '../../theme.module.css';
    text-align: center;
}

.smallHeading {
    composes: heading-4 from '../../theme.module.css';
    font-size: clamp(1.75rem, 1.6813rem + 0.2656vw, 2rem);
    margin-top: 32px;
    text-align: left;
}

.description {
    composes: heading-2 from '../../theme.module.css';
    color: rgba(0, 0, 0, 0.75);
}

.boxContainer {
    display: flex;
    margin-top: 72px;
    justify-content: space-between;
}

.box {
    display: flex;
    width: 588px;
    max-width: 50%;
    flex-direction: column;
    padding: 32px;
    min-height: 629px;
    background: #FFFFFF;
    border-radius: 16px;
    margin: 0 16px;
}

.boxItem {
    composes: body-2 from '../../theme.module.css';
}

.boxList {
    padding: 0 20px;
}

.boxList>li {
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    color: rgba(0, 0, 0, 0.75);
    margin-bottom: 18px;
}

.boxList>li::marker {
    content: url("../../../assets/landing_page/list-item.svg");
    height: 27px;
}

.boxContent {
    display: flex;
    flex-direction: column;
    padding: 32px 32px 0px 32px;
    flex-grow: 1;
}

.primaryButton {
    composes: button-2 from '../../theme.module.css';
    composes: primaryButton from '../../theme.module.css';
    height: 56px;
    margin-top: auto;
    margin-bottom: 32px;
}

@media (min-width: 1440px) {
    .mainContent {
        align-items: center;
    }

    .header {
        width: 85%;
        text-align: left;
    }

    .subHeader {
        width: 85%;
        text-align: left;
    }

    .description {
        width: 85%;
        text-align: left;
    }

    .boxContainer {
        justify-content: center;
    }

    .boxContainer>div.box:first-child {
        margin-right: 16px;
    }
}


@media (max-width: 959.95px) {
    .container {
        padding: 32px;
        height: 100%;
    }

    .boxContainer {
        flex-direction: column;
        align-items: center;
        width: unset;
        margin-top: 32px;
    }

    .box {
        width: 90%;
        height: 100%;
        margin-bottom: 32px;
    }
}