.mainContainer {
    composes: container from '../../theme.module.css';
    background-color: #FFFFFF;
}

@media (min-width: 320px) and (max-width: 390px) {
    .container {
        padding: 72px 24px !important;
    }
}

.mainContent {
    composes: mainContent from '../../theme.module.css';
    flex-direction: column;
}

.heading {
    composes: heading-2 from '../../theme.module.css';
    margin-bottom: 72px;
}

.caseImage {
    width: 100%;
    border-radius: 5px;
}

.brandLogo {
    margin-top: 48px;
    height: 25px;
}

.brandLogoAmazon {
    margin-top: 52px;
    height: 35px;
    margin-bottom: -14px;
}

#amazon-logo {
    height: 80px;
}

.description {
    composes: heading-5 from '../../theme.module.css';
    margin: 32px 0;
}

.readMoreButton {
    composes: linkButton from '../../theme.module.css';
}

.moreStudiesButton {
    composes: button-2 from '../../theme.module.css';
    composes: secondaryButton from '../../theme.module.css';
    width: 251px;
    height: 64px;
    margin-top: 24px;
}

.studiesContainer {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0px;
    margin-bottom: 16px;
}

.case {
    width: 48%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

@media (max-width: 959.95px) {
    .mainContainer {
        padding: 32px;
    }

    .heading {
        margin-bottom: 32px;
    }

    .case {
        width: 100%;
        margin-bottom: 24px;
    }

    .studiesContainer {
        flex-direction: column;
    }

    .moreStudiesButton {
        align-self: center;
    }

    .description {
        margin: 24px 0;
    }
}