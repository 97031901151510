.form {
  margin: 20px 0px;
}

.forgotPassword {
  width: 250px;
}

.signIn {
  background-color: #1e3b47;
  color: #fff;
  width: 100%;
}

.registerHere {
  width: 100%;
}

.signIn:hover {
  background-color: #0c232d;
}

.textField {
  margin-right: 8px;
  width: 100%;
  margin-bottom: 20px;
}

.forgotPassword {
  color: #4977b3;
  text-decoration: none !important;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: max-content;
}

.forgotPassword:hover {
  cursor: pointer;
  opacity: 0.6;
}

@media (max-width: 959.95px) {
  .modal {
    width: calc(100%);
  }

  .container {
    padding: 16px;
  }
}

@media (min-width: 959.95px) {
  .container {
    padding: 80px;
  }
}
